import * as React from "react";

import ContactForm from "./contactForm";

export default props => (
  <footer className="w-100">
    <div className="cf w-90 mw8 db center pb4 mb4">
      <h2 className="fl w-100 f2 f1-l mt4 mt5-l mb4">
        Got a project? Let's talk.
      </h2>
      <p className="mb5 lh-copy measure">
        Interested in working together? Or just up for a chat? I'd love to hear
        from you. I work with commercial clients, agencies and non-profits and I
        love using my skills to do something good.
      </p>
      <div className="fl w-100 w-50-ns mb5">
        <h3 className="f3 mb3">Connect</h3>
        <div className="fl w-50 w-100-ns mb3">
          <p className="lh-copy mb2 f6">LinkedIn</p>
          <a
            className="link dark-blue dim"
            href="https://www.linkedin.com/in/arresteddeveloper/"
          >
            Michael Watts
          </a>
        </div>
        <div className="fl w-50 w-100-ns">
          <p className="lh-copy mb2 f6">Github</p>
          <a
            className="link dark-blue dim"
            href="https://github.com/arrested-developer"
          >
            @arrested-developer
          </a>
        </div>
      </div>
      <div className="fl w-100 w-50-ns">
        <h3 className="f3 mb3">Project Enquiries</h3>
        <ContactForm />
      </div>
    </div>
    <div className="w-100 tr">
      <div className="db w-90 bt b--dotted mw8 center pv4">
        Styled with Tachyons{" "}
        <span role="img" aria-label="palette emoji">
          🎨
        </span>
      </div>
    </div>
  </footer>
);
