import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Nav from "./tachyons/nav/logoLinksInline";

const HeaderLayout = ({ logo }) => (
  <header>
    <Nav logo={logo}>
      <Nav.Link to="/" className="mr3">
        Home
      </Nav.Link>
      <Nav.Link to="/blog" className="mr3">
        Blog
      </Nav.Link>
      <Nav.ExternalLink href="https://sssnek.netlify.com">
        <span role="img" aria-label="snake">
          🐍
        </span>
      </Nav.ExternalLink>
    </Nav>
  </header>
);

HeaderLayout.propTypes = {
  logo: PropTypes.object.isRequired,
};

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(relativePath: { eq: "michael.jpg" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <HeaderLayout logo={data.headerLogo.childImageSharp.fixed} />;
};

export default Header;
