import * as React from "react";

import Button from "./tachyons/buttons";

const Input = ({ className, element, label, value, onChange, ...props }) => (
  <label className={`db lh-copy f6 mb2 ${className}`}>
    {label}
    {React.createElement(element, {
      className:
        "input-reset db mt1 pa2 ba b--dark-blue dark-blue bg-transparent w-100",
      value,
      onChange: e => onChange(e.target.value),
      ...props,
    })}
  </label>
);

export default props => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        name,
        email,
        message,
      }),
    })
      .then(() => alert("message sent"))
      .catch(err => alert(err));
  };
  return (
    <form
      className="w-100"
      method="post"
      data-netlify="true"
      onSubmit={handleSubmit}
      name="contact"
    >
      <input type="hidden" name="form-name" value="contact" />
      <fieldset id="sign_up" className="ba b--transparent pa0 ma0">
        <Input
          element="input"
          type="text"
          name="name"
          label="Name"
          value={name}
          onChange={setName}
        />
        <Input
          element="input"
          type="email"
          name="email"
          label="Email"
          value={email}
          onChange={setEmail}
        />
        <Input
          element="textarea"
          rows={5}
          name="message"
          label="Tell me about your project"
          value={message}
          onChange={setMessage}
        />
      </fieldset>
      <div className="">
        <Button
          as="input"
          type="submit"
          value="Send"
          className="mt3 ba bw2 dark-blue grow"
        />
      </div>
    </form>
  );
};
